import { Icon, Text, Grid } from '@lemonenergy/lemonpie'
import styled, { css } from 'styled-components'

import Card from '~/components/Card'
import Section from '~/components/Section'
import Subtitle from '~/components/Subtitle'
import Title from '~/components/Title'

const IconWrapper = styled(Icon)(
  ({ theme: { colors, radii, spacing } }) => css`
    align-items: center;
    background-color: ${colors.primary.lightest};
    border-radius: ${radii.round};
    color: ${colors.primary.main};
    display: flex;
    height: ${spacing(6)};
    justify-content: center;
    margin-bottom: ${spacing.sm};
    width: ${spacing(6)};
  `,
)

const SectionWrapper = styled(Section)(
  ({ theme: { media, spacing } }) => css`
    ${Grid.Col} {
      ${Title} {
        margin-bottom: ${spacing.md};
        text-align: center;
      }

      > ${Text}:not(${Title}) {
        margin-bottom: ${spacing(12)};
        text-align: center;
      }

      ${Card} {
        ${IconWrapper} {
          margin-bottom: ${spacing.sm};
        }

        ${Text}:last-child {
          margin-top: ${spacing.md};
        }
      }

      ${media.only.xs} {
        > ${Text}:not(${Title}) {
          margin-bottom: ${spacing(6)};
        }

        ${Card} {
          margin-bottom: ${spacing.md};
        }
      }
    }
  `,
)

const TheLemonWay: React.FC<{ id?: string }> = ({ id }) => (
  <SectionWrapper divider>
    <Grid.Container>
      <Grid.Row>
        <Grid.Col>
          <Title id={id} bold={{ xs: true, sm: false }} variant="title100">
            energia do jeito Lemon
          </Title>
          <Subtitle>
            Pode até parecer, mas energia elétrica não é tudo igual.
          </Subtitle>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col xs={12} sm={4}>
          <Card bordered>
            <IconWrapper name="money" size="sm" />
            <Text variant="title300">Economia na Energia</Text>
            <Text variant="body100">
              Com a Lemon seus gastos com energia são sempre menores! É economia
              no bolso o ano todo.
            </Text>
          </Card>
        </Grid.Col>
        <Grid.Col xs={12} sm={4}>
          <Card bordered>
            <IconWrapper name="bolt" size="sm" />
            <Text variant="title300">Energia limpa</Text>
            <Text variant="body100">
              Sua energia será gerada em pequenas usinas da sua região que
              garantem uma produção sem poluir o meio ambiente.
            </Text>
          </Card>
        </Grid.Col>
        <Grid.Col xs={12} sm={4}>
          <Card bordered>
            <IconWrapper name="shine" size="sm" />
            <Text variant="title300">Energia mais digital</Text>
            <Text variant="body100">
              Chega de papelada com letrinhas miúdas e complicadas. Por aqui
              somos simples e 100% digitais.
            </Text>
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  </SectionWrapper>
)

export default TheLemonWay
