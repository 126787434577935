import { Icon, Text, Grid, Button } from '@lemonenergy/lemonpie'
import styled, { css } from 'styled-components'

import LinkWithIcon from '~/components/LinkWithIcon'
import RemixLink from '~/components/RemixLink'
import Title from '~/components/Title'
import Images from '~/constants/builderImages'

const Background = styled.div(
  ({ theme: { media, spacing } }) => css`
    background-image: url(${Images.hero_home});
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    right: 0;
    position: absolute;
    top: 0;
    width: 50%;

    ${media.only.xs} {
      height: ${spacing(42.25)};
      position: relative;
      width: 100%;
      background-image: url(${Images.hero_home_mobile});
    }
  `,
)

const Container = styled.div(
  ({ theme: { colors, spacing } }) => css`
    background-color: ${colors.primary.main};
    color: ${colors.neutral.lightest};
    position: relative;
    width: 100%;

    ${Grid.Col} {
      > ${Icon} {
        color: ${colors.secondary.light};
        margin-bottom: ${spacing.md};
      }

      ${LinkWithIcon} {
        color: ${colors.secondary.light};
        margin-top: ${spacing.lg};
      }

      ${Title} {
        margin-bottom: ${spacing.md};
      }

      ${Button} {
        margin-top: ${spacing.lg};
      }
    }
  `,
)

const GridContainer = styled(Grid.Container)(
  ({ theme: { media, spacing } }) => css`
    align-items: center;
    display: flex;
    height: ${spacing(83)};
    width: 100%;

    ${media.only.xs} {
      height: ${spacing(60)};
    }
  `,
)

type HeroProps = {
  ctaButtonLink?: string
}

const Hero = ({ ctaButtonLink }: HeroProps) => (
  <Container>
    <GridContainer>
      <Grid.Row>
        <Grid.Col xs={12} sm={5}>
          <Icon name="shine" size={{ xs: 'sm', sm: 6 }} />
          <Title>
            chegou a hora de gerar a sua própria energia e economizar sem
            precisar investir em nada
          </Title>
          <Text variant={{ xs: 'body100', sm: 'subtitle200' }}>
            Com a ajuda da Lemon, você sempre - e é sempre mesmo - vai ter
            energia limpa e economia para o seu negócio. Tudo digital!
          </Text>
          <LinkWithIcon
            name="arrow-right"
            rotateIcon={90}
            href="#energia-do-jeito-lemon"
            bold
          >
            Quero saber mais
          </LinkWithIcon>
          <Grid.Hidden sm>
            <Button color="neutral" to={ctaButtonLink} forwardedAs={RemixLink}>
              Realizar Cadastro
            </Button>
          </Grid.Hidden>
        </Grid.Col>
      </Grid.Row>
    </GridContainer>
    <Background title="Empreendedor feliz por escolher a Energia Lemon." />
  </Container>
)

export default Hero
