import { Text, Grid } from '@lemonenergy/lemonpie'
import styled, { css } from 'styled-components'

import Section from '~/components/Section'
import Tag from '~/components/Tag'

const ZeroCostCol = styled.div(
  ({ theme: { media, spacing } }) => css`
    ${media.only.xs} {
      margin-bottom: ${spacing(8)};
    }
  `,
)

const ZeroCostText = styled(Text)(
  ({ theme: { fontWeight, media, spacing } }) => css`
    font-size: ${spacing(12)};
    font-weight: ${fontWeight.semibold};
    line-height: ${spacing(11)};
    margin-bottom: ${spacing(3)};

    ${media.sm} {
      font-size: ${spacing(16)};
      line-height: ${spacing(15)};
    }
  `,
)

const SectionWrapper = styled(Section)(
  ({ theme: { media, spacing } }) => css`
    ${Grid.Col} {
      > ${Text}:first-child:not(${ZeroCostText}) {
        margin-bottom: ${spacing(4)};

        ${media.sm} {
          margin-bottom: ${spacing(6)};
        }
      }
    }
  `,
)

const ZeroCost = () => (
  <SectionWrapper variant="primary.main">
    <Grid.Container>
      <Grid.Row>
        <Grid.Col
          align={{ xs: 'left', sm: 'center' }}
          justify="center"
          xs={12}
          sm={6}
        >
          <ZeroCostCol>
            <ZeroCostText>
              custo <br /> zero
            </ZeroCostText>
            <Tag name="sun" rotate={-6} size={{ xs: 'md', sm: 'lg' }}>
              sem pegadinhas
            </Tag>
          </ZeroCostCol>
        </Grid.Col>
        <Grid.Col justify="center" xs={12} sm={6}>
          <Text bold variant={{ xs: 'subtitle100', sm: 'title100' }}>
            R$ 0 de investimento
            <br />
            R$ 0 de taxas
            <br />
            R$ 0 de manutenção
          </Text>
          <Text variant={{ xs: 'body100', sm: 'title200' }}>
            Com a Lemon você paga apenas os custos da sua energia e tem a
            garantia de uma conta mais econômica e sustentável.
          </Text>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  </SectionWrapper>
)

export default ZeroCost
